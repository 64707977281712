import { merge } from '@ecp/utils/common';

import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './ServicingHeaderBody.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(
    params,
    merge({}, styleOverrides, {
      props: {
        classes: {
          active: css({
            color: theme.palette.secondary.main,
            borderBottomColor: theme.palette.secondary.main,
          }),
          button: css({
            ...theme.mixins.setColorSvg(theme.palette.secondary.main),
            color: theme.palette.secondary.main,
          }),
        },
      },
    }),
  );
};
