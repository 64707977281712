import type { GeneratedBrandData } from '../brand.types';

export const brand: GeneratedBrandData = {
  auto: {
    partnerCompanyName: 'CONNECT, powered by American Family Insurance',
    mainOperationHours: [
      'Monday - Friday: 7 a.m. - 10 p.m. CT',
      'Saturday: 8:30 a.m. - 7 p.m. CT',
      'Sunday: CLOSED',
    ],
    mainCustServPhoneNum: '1-800-535-2001',
    mainBrandNameShort: 'CONNECT, powered by American Family Insurance',
    brandingType: 'Private Label',
    salesOperationHours: ['Monday - Friday: 7 a.m. - 6 p.m. CT', 'Weekends: CLOSED'],
    salesPhoneNum: '1-800-535-2001',
    salesChatHours: [],
    serviceChatHours: ['Monday - Friday: 7 a.m. - 6 p.m. CT', 'Weekends: CLOSED'],
    servicingUrl: 'https://service.CONNECTByAmFam.com',
    claimPhoneNum: '1-800-535-2001',
    glassClaimPhoneNum: '1-800-535-2001',
    inboundServiceEmail: 'PolicyDocs@connectbyamfam.com',
    roadsideAssistancePhoneNum: '1-888-742-4572',
    serviceAddress: {
      addressLine1: '6000 American Parkway',
      city: 'Madison',
      state: 'WI',
      zip: '53783',
      type: 'General Service Mailing Address',
      zipPlus4: '0001',
    },
    paymentsAddress: {
      addressLine1: 'CONNECT, powered by American Family Insurance',
      addressLine2: '6000 American Parkway',
      attention: '',
      city: 'Madison',
      state: 'WI',
      zip: '53777',
      type: 'Payments Mailing Address',
      zipPlus4: '0001',
    },
    uwCompany: 'CONNECT',
    uwCompanyCopyright: 'American Family Connect Property and Casualty Insurance Company, Inc.',
  },
  home: {
    partnerCompanyName: 'CONNECT, powered by American Family Insurance',
    mainOperationHours: [
      'Monday - Friday: 7 a.m. - 10 p.m. CT',
      'Saturday: 8:30 a.m. - 7 p.m. CT',
      'Sunday: CLOSED',
    ],
    mainCustServPhoneNum: '1-800-535-2001',
    mainBrandNameShort: 'CONNECT, powered by American Family Insurance',
    brandingType: 'Private Label',
    salesOperationHours: ['Monday - Friday: 7 a.m. - 6 p.m. CT', 'Weekends: CLOSED'],
    salesPhoneNum: '1-800-535-2001',
    salesChatHours: [],
    serviceChatHours: ['Monday - Friday: 7 a.m. - 6 p.m. CT', 'Weekends: CLOSED'],
    servicingUrl: 'https://service.CONNECTByAmFam.com',
    claimPhoneNum: '1-800-535-2001',
    glassClaimPhoneNum: '1-800-535-2001',
    inboundServiceEmail: 'PolicyDocs@connectbyamfam.com',
    roadsideAssistancePhoneNum: '1-888-742-4572',
    serviceAddress: {
      addressLine1: '6000 American Parkway',
      city: 'Madison',
      state: 'WI',
      zip: '53783',
      type: 'General Service Mailing Address',
      zipPlus4: '0001',
    },
    paymentsAddress: {
      addressLine1: 'CONNECT, powered by American Family Insurance',
      addressLine2: 'PO Box 5300',
      attention: '',
      city: 'Binghamton',
      state: 'NY',
      zip: '13902',
      type: 'Payments Mailing Address',
      zipPlus4: '9953',
    },
    uwCompany: 'Homesite',
    uwCompanyCopyright: 'Homesite Group Incorporated',
  },
};
