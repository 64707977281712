import { Stack } from '@mui/material';

import { Link } from '@ecp/components';
import { env } from '@ecp/env';
import { NavLink } from '@ecp/features/servicing/shared/components';
import { generateHelpPath, PAGE_PATH } from '@ecp/features/servicing/shared/routing';

import { useStyles } from './DisclosureLinks.styles';
import { ThirdPartyPrivacyNoticeLink } from './ThirdPartyPrivacyNoticeLink';

export const DisclosureLinksConnect: React.FC = () => {
  const { classes } = useStyles();

  return (
    <Stack spacing={1}>
      <NavLink
        to={generateHelpPath(PAGE_PATH.DISCLOSURES_PRIVACYPOLICY, 'connect')}
        trackingName='privacy_policy_link'
        trackingLabel='privacy_policy'
      >
        Privacy Policy
      </NavLink>
      <NavLink
        to={generateHelpPath(PAGE_PATH.DISCLOSURES_CLAIMSTEXTING, 'connect')}
        trackingName='texting_terms_conditions_link'
        trackingLabel='texting_terms_conditions'
      >
        Texting Terms and Conditions
      </NavLink>
      <ThirdPartyPrivacyNoticeLink />
      <Link
        href={env.ccpaUrl}
        target='_blank'
        className={classes.link}
        trackingName='ccpa_request_form_link'
        trackingLabel='ccpa_request_form'
      >
        CCPA Request Form
      </Link>

      <p>Security</p>
      <Stack component='ul' spacing={1}>
        <li>
          <NavLink
            to={generateHelpPath(PAGE_PATH.DISCLOSURES_PROTECTYOURINFORMATION, 'connect')}
            classes={{ root: classes.link }}
            trackingName='learn_more_about_how_we_protect_your_information_link'
            trackingLabel='learn_more_about_how_we_protect_your_information'
          >
            Learn more about how we protect your information
          </NavLink>
        </li>
        <li>
          <NavLink
            to={generateHelpPath(PAGE_PATH.DISCLOSURES_PROTECTYOURSELF, 'connect')}
            trackingName='learn_more_about_how_you_can_protect_yourself_link'
            trackingLabel='learn_more_about_how_you_can_protect_yourself'
          >
            Learn more about how you can protect yourself
          </NavLink>
        </li>
        <li>
          <NavLink
            to={generateHelpPath(PAGE_PATH.DISCLOSURES_EMAIL_FRAUD, 'connect')}
            trackingName='learn_more_about_email_fraud_link'
            trackingLabel='learn_more_about_email_fraud'
          >
            Learn more about email fraud
          </NavLink>
        </li>
      </Stack>

      <NavLink
        to={generateHelpPath(PAGE_PATH.DISCLOSURES_TERMSOFUSE, 'connect')}
        trackingName='terms_of_use_link'
        trackingLabel='terms_of_use'
      >
        Terms of Use
      </NavLink>
    </Stack>
  );
};
