import { merge } from '@ecp/utils/common';

import type { PaletteOverrides } from '@ecp/themes/base';

// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { palette as basePalette } from '../../../../base/src';

const paletteOverrides: PaletteOverrides = {
  actions: {
    disabledBg: '#eaeaea',
    disabledText: '#78777b',
  },
  error: {
    contrastText: '#ffffff',
    dark: '#a10025',
    darkText: '#25282a',
    light: '#f9edf0',
    main: '#a10025',
  },
  grey: {
    '50': '#ffffff',
    '100': '#fafafa',
    '200': '#f2f2f2',
    '300': '#eaeaea',
    '400': '#d4d4d4',
    '500': '#cccccc',
    '600': '#afaeb0',
    '700': '#78777b',
    '800': '#4c4c4c',
    '900': '#222222',
  },
  icon: {
    dark: '#25282a',
    default: '#ffffff',
    hover: '#287ba4',
    stroke: '#78777b',
  },
  info: {
    contrastText: '#ffffff',
    dark: '#002855',
    darkText: '#25282a',
    light: '#edf0f3',
    main: '#002855',
  },
  other: {
    backdrop: '#22222266',
    background: '#5b6770',
    backgroundContrastText: '#ffffff',
    body: '#ffffff',
    border: '#d4d4d4',
    caution: '#a9670c',
    cookieBanner: '#3071a9',
    divider: '#c9c9c9',
    genesysChatBg: '#002855',
    genesysChatIcon: '#ffffff',
    snackbar: '#4c4c4c',
    tooltip: '#4c4c4c',
  },
  partner: {
    footerBackground: '#ffffff',
    footerTextLink: '#257298',
    footerTextPrimary: '#25282a',
    footerTextSecondary: '#4c4c4c',
    headerBackground: '#ffffff',
  },
  primary: {
    background: '#fafafa',
    border: '#002855',
    contrastText: '#ffffff',
    dark: '#002855',
    darkText: '#002855',
    light: '#edf0f3',
    main: '#002855',
  },
  secondary: {
    background: '#f2f2f2',
    border: '#287ba4',
    contrastText: '#ffffff',
    dark: '#1e5c7b',
    darkText: '#1e5c7b',
    light: '#d5eefa',
    main: '#287ba4',
  },
  shadow: {
    primary: '#00000033',
  },
  success: {
    complete: '#287ba4',
    contrastText: '#ffffff',
    dark: '#1e5c7b',
    darkText: '#25282a',
    light: '#eff5f8',
    main: '#287ba4',
  },
  text: {
    disabled: '#78777b',
    hover: '#1e5c7b',
    link: '#257298',
    primary: '#25282a',
    secondary: '#4c4c4c',
    tertiary: '#5b6770',
  },
  warning: {
    contrastText: '#ffffff',
    dark: '#aa8708',
    darkText: '#25282a',
    light: '#fefaeb',
    main: '#f7ce3e',
  },
};

export const palette = merge({}, basePalette, paletteOverrides);
