import { Link } from '@ecp/components';

import { useStyles } from './ThirdPartyPrivacyNoticeLink.styles';

export const ThirdPartyPrivacyNoticeLink: React.FC = () => {
  const { classes } = useStyles();

  return (
    <Link
      href='https://www.connectbyamfam.com/documents/2022_10_10_draft-xica08_(003).pdf'
      target='_blank'
      className={classes.link}
      trackingName='third_party_privacy_notice_link'
      trackingLabel='third_party_privacy_notice'
    >
      Third Party Privacy Notice
    </Link>
  );
};
