import { merge } from '@ecp/utils/common';

import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './HeaderNav.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(
    params,
    merge({}, styleOverrides, {
      props: {
        classes: {
          active: css({
            '& .MuiStack-root > svg > path': { fill: theme.palette.secondary.main },
            color: `${theme.palette.secondary.main} !important`,
            borderBottomColor: `${theme.palette.secondary.main} !important`,
          }),
          link: css({
            [`.${styleOverrides?.props?.classes?.activeItem} &, &.${styleOverrides?.props?.classes?.linkWithOpenSubmenu}, &:hover`]:
              {
                ...theme.mixins.setColorSvg(theme.palette.secondary.main),
                borderBottomColor: theme.palette.secondary.main,
                color: theme.palette.secondary.main,
              },
          }),
          linkWithOpenSubmenu: css({
            '& .MuiStack-root > svg > path': { fill: theme.palette.secondary.main },
            color: `${theme.palette.secondary.main} !important`,
            borderBottomColor: `${theme.palette.secondary.main} !important`,
          }),
        },
      },
    }),
  );
};
